<template>
	<div id="courseRen"></div>
</template>

<script>
import * as echarts from 'echarts/core'
import { TooltipComponent, GridComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer])
export default {
	props: ['courseHot'],
	mounted() {
		var chartDom = document.getElementById('courseRen')
		var myChart = echarts.init(chartDom)
		var option

		option = {
			grid: {
				//柱状图位置
				top: 17,
				left: 70,
				right: 10,
				bottom: 5,
				containLabel: true,
			},
			yAxis: {
				type: 'value',

				axisLine: {
					show: true,
					lineStyle: {
						color: '#00c7ff',
						width: 1,
						type: 'dotted',
					},
				},
				axisTick: {
					show: false,
				},
				splitLine: {
					//格线样式
					show: false,
				},
			},
			xAxis: {
				type: 'category',
				data: this.courseHot.title,
				axisLine: {
					lineStyle: {
						color: '#00c7ff',
						width: 1,
						type: 'dotted',
					},
				},
				axisTick: {
					show: false,
				},
				axisLabel: {
					show: true,
					interval: 0,
					rotate: -90,
					margin: 5,
					textStyle: {
						color: '#00c7ff',
					},
				},
			},
			grid: {
				// 控制图的大小，调整下面这些值就可以，
				x: 25,
				x2: 20,
				y2: 150, // y2可以控制 X轴跟Zoom控件之间的间隔，避免以为倾斜后造成 label重叠到zoom上
			},
			tooltip: {
				trigger: 'item',
                confine:true
			},
			series: [
				{
					data: this.courseHot.value,
					type: 'bar',
					itemStyle: {
						//柱样式
						normal: {
							color: '#00c7ff',
							opacity: 0.8,
						},
					},
					label: {
						show: true,
						position: 'top',
						valueAnimation: true,
						color: '#00c7ff',
					},
				},
			],
		}

		option && myChart.setOption(option)
	},
}
</script>

<style lang="less" scoped>
#courseRen {
	height: 100%;
	width: 100%;
}
</style>
