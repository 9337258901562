<template>
	<div class="box">
		<div class="content">
			<div class="title">
				<!-- <span class="circle"></span> -->
				<span>{{ title }}</span>
			</div>
			<div class="count">
				<span>{{ count }}</span>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'baseCount',
	props: ['title', 'count'],
}
</script>
<style lang="less" scoped>
.box {
	background-color: #fff;
	border-radius: 10px;
	// border-left: 3px solid red;
	// height: 40px;
	margin: 5%;
	.content {
		padding: 5%;

		.count {
			text-align: center;
			// padding-left: 10px;
			font-size: 1.3rem;
		}
		.title {
			// padding-left: 10px;
			margin-bottom: 4%;
			font-size: 0.5rem;
			text-align: center;
			// .circle {
			// 	display: inline-block;
			// 	width: 10px;
			// 	height: 10px;
			// 	background-color: rgb(248, 223, 223);
			// 	border-radius: 5px;
			// }
		}
	}
}
// .box {
// 	display: flex;
// 	flex-direction: row;
// 	width: 50%;
// 	height: 90px;
// 	border-radius: 5px;
// 	padding: 3% 6%;
// 	margin: 5%;
// 	box-sizing: border-box;
// 	background-color: rgb(248, 248, 248);
// .img {
// 	flex: 1;
// 	padding: 10%;
// 	img {
// 		max-width: 100%;
// 		max-height: 100%;
// 	}
// }
// .content {
// 	flex: 2;
// 	height: 100%;
// 	.count {
// 		height: 50%;
// 		position: relative;
// 		span {
// 			position: absolute;
// 			bottom: 0px;
// 			padding: 0px;
// 			margin: 0px;
// 		}
// 	}
// 	.title {
// 		height: 50%;
// 	}
// }
// }
</style>
