<template>
	<div id="main"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
	props: ['courseHot'],
	mounted() {
		var chartDom = document.getElementById('main')
		var myChart = echarts.init(chartDom)
		var option

		option = {
    legend: {
        top: 'bottom'
    },
    toolbox: {
        show: true,
    },
    series: [
        {
            name: '面积模式',
            type: 'pie',
            radius: [30, 70],
            center: ['50%', '30%'],
            roseType: 'area',
            itemStyle: {
                borderRadius: 8
            },
            data:this.courseHot
        }
    ]
};

		option && myChart.setOption(option)
	},
}
</script>

<style lang="less" scoped>
#main {
	height: 100%;
	width: 100%;
}
</style>
