<template>
    <div class="box">
        <div class="title">{{title}}</div>
        <div class="echart-box"><slot name="echart" /></div>
    </div>
</template>
<script>
export default {
    name:"mobileBox",
    props:["title"]
}
</script>
<style lang="less" scoped>
.box{
    margin: 5%;
    .title{
        border-left: 3px solid rgb(1, 1, 58);
        padding-left: 1%;
    }
    .echart-box{
        height: 100%;
    }
}
</style>