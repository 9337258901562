<template>
  <div class="root">
    <div class="section">
      <div class="base-all">
        <div
          v-for="(item, index) in topBaseCount"
          :key="index"
          class="base-count"
        >
          <BaseCount :count="item.num" :title="item.title"></BaseCount>
        </div>
      </div>
      <Box title="课程分析统计">
        <template v-slot:echart>
          <div class="echart">
            <CourseLabelPie
              v-if="courseLabel.length > 0"
              :courseLabel="courseLabel"
              style="width: 100%; height: 300px"
            />
          </div>
        </template>
      </Box>
      <Box title="场景预约人次统计">
        <template v-slot:echart>
          <div class="echart">
            <SceneRowBar
              v-if="siteHot"
              :siteHot="siteHot"
              style="width: 100%; height: 300px"
            />
          </div>
        </template>
      </Box>
      <Box title="课程分类预计人次统计">
        <template v-slot:echart>
          <div class="echart">
            <CourseLabelRadar
              v-if="labelHot"
              :labelHot="labelHot"
              style="width: 100%; height: 300px"
            />
          </div>
        </template>
      </Box>
      <Box title="场景趋势图"
        ><template v-slot:echart>
          <div class="echart">
            <div class="site-search">
              <el-select
                class="site-select"
                style="width: 45vw"
                @change="getSiteTimeHot(true)"
                v-model="site"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in siteOptions"
                  :key="item.siteId"
                  :label="item.siteName"
                  :value="item.siteId"
                >
                </el-option>
              </el-select>
            </div>
            <SceneHotLine
              v-if="siteTimeHot"
              :siteTimeHot="siteTimeHot"
              style="width: 100%; height: 300px"
              class="site-hot"
            />
          </div> </template
      ></Box>
      <Box title="热门课程TOP10">
        <template v-slot:echart>
          <div class="echart">
            <!-- <CourseHot
							v-if="courseHot.length > 0"
							:courseHot="courseHot"
							style=" width:100%; height:400px;"
						/> -->
            <CourseRenColbar
              v-if="courseHot"
              :courseHot="courseHot"
              style="width: 100%; height: 400px"
            />
          </div>
        </template>
      </Box>
      <Box title="考勤实时统计">
        <template v-slot:echart>
          <div class="echart">
            <div class="data-search">
              <el-date-picker
                class="data-select"
                style="width: 37vw"
                v-model="date"
                type="date"
                :clearable="false"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                @change="getRealTimeAttendanceCount"
              >
              </el-date-picker>
            </div>
            <KqBar
              :kqData="kqData"
              v-if="kqData"
              style="width: 100%; height: 400px"
            />
          </div>
        </template>
      </Box>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import BaseCount from "./components/baseCount.vue";
import Box from "./components/box.vue";
import CourseLabelPie from "../../data/components/courseLabelPie.vue";
import SceneRowBar from "./components/siteCount.vue";
import CourseLabelRadar from "../../data/components/courseLabelRadar.vue";
import CourseHot from "./components/CourseHot.vue";
import SceneHotLine from "../../data/components/sceneHotLine.vue";
import KqBar from "../../data/components/kqBar.vue";
import CourseRenColbar from "./components/courseRenColBar";
import {
  getCourseLabel_api,
  getRealTimeAttendanceCount_api,
  getTopBaseCount_api,
  getCourseHot_api,
  getSiteHot_api,
  getSiteTimeHot_api,
  getTopOnlineCount_api,
} from "@/api/data.js";
export default {
  name: "Mobile",
  components: {
    BaseCount,
    Box,
    CourseLabelPie,
    SceneRowBar,
    CourseLabelRadar,
    CourseHot,
    SceneHotLine,
    KqBar,
    CourseRenColbar,
  },
  data() {
    return {
      siteOptions: [], // 场景列表
      site: "", // 场景下拉选择器
      date: new Date(), // 日期选择器默认选中今天
      courseLabel: [], // 课程标签及其课程数
      labelHot: null, // 标签热度
      kqData: null, // 考勤数据
      topBaseCount: [], // 顶部基本数据统计
      courseHot: null, // 课程热度统计
      siteHot: null, // 场景热度统计
      siteTimeHot: null, // 场景时间轴热度
      pickerOptions: {
        //控制时间范围
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    //顶部在线人数统计
    async getTopOnlineCount() {
      let a = await getTopOnlineCount_api({
        tenantId: this.$tenantId,
      });
      this.topBaseCount.push({
        title: "在线人数",
        num: a,
      });
    },
    // 顶部基本数据统计
    async getTopBaseCount() {
      this.topBaseCount = []; // 顶部基本数据统计
      let { code, data, msg } = await getTopBaseCount_api({
        tenantId: this.$tenantId,
      });
      if (code == 200) {
        this.getTopOnlineCount();
        this.topBaseCount.push({
          title: "场景数",
          num: data.siteNum,
        });
        this.topBaseCount.push({
          title: "课程数",
          num: data.courseNum,
        });
        this.topBaseCount.push({
          title: "累计开课数",
          num: data.teachRecordNum,
        });
        this.topBaseCount.push({
          title: "累计预约人次",
          num: 5049,
          //num: data.reserveCourseNum-391,
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 饼状图\雷达图，获取标签及课程数，热度
    async getCourseLabel() {
      let { code, msg, data } = await getCourseLabel_api({
        tenantId: this.$tenantId,
      });
      if (code == 200) {
        this.labelHot = {};
        this.labelHot.title = [];
        this.labelHot.value = [];
        let max = 0;
        data.forEach((item) => {
          if (item.tagHeat > max) {
            max = item.tagHeat;
          }
        });
        this.courseLabel = data.map((item) => {
          item.value = item.tagCount;
          item.name = item.tagName;
          this.labelHot.title.push({
            name: item.tagName + "(" + item.tagHeat + ")",
            max: max,
          });
          this.labelHot.value.push(item.tagHeat);
          return item;
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 获取场景热度
    async getSiteHot() {
      this.siteHot = null;
      let { code, data, msg } = await getSiteHot_api({
        tenantId: this.$tenantId,
      });
      if (code == 200) {
        this.siteHot = {};
        this.siteHot.title = [];
        this.siteHot.value = [];
        data.forEach((item) => {
          if (item.siteHeat > 0) {
            this.siteHot.title.push(item.siteName);
            this.siteHot.value.push(item.siteHeat);
          }
        });
        this.siteOptions = data;
        this.site = data[0].siteId;
        this.getSiteTimeHot();
      } else {
        this.$message.error(msg);
      }
    },
    // 获取场景趋势图
    async getSiteTimeHot(bool) {
      if (bool) this.siteTimeHot = null;
      if (!this.site) return this.$message.error("请选择场景");
      let { code, data, msg } = await getSiteTimeHot_api({
        siteId: this.site,
        tenantId: this.$tenantId,
      });
      if (code == 200) {
        this.siteTimeHot = {};
        this.siteTimeHot.title = [];
        this.siteTimeHot.value = [];
        data.forEach((item) => {
          this.siteTimeHot.title.push(item.countTime);
          this.siteTimeHot.value.push(item.siteHeatByDay);
        });
      } else {
        this.$message.error(msg);
      }
    },
    // 实时考勤统计
    async getRealTimeAttendanceCount() {
      this.kqData = null;
      let { code, data, msg } = await getRealTimeAttendanceCount_api({
        dateStr: moment(this.date).format("YYYY-MM-DD"),
        tenantId: this.$tenantId,
      });
      if (code == 200) {
        this.kqData = data;
      } else {
        this.$message.error(msg);
      }
    },
    // 课程热度统计（预约人次）
    async getCourseHot() {
      // this.courseHot = null // 课程热度统计
      let { code, data, msg } = await getCourseHot_api({
        tenantId: this.$tenantId,
      });
      // if (code == 200) {
      // 	this.courseHot = data.map((item) => {
      // 		item.value = item.courseHeat
      // 		item.name = item.courseName
      // 		return item
      // 	})
      if (code == 200) {
        this.courseHot = {};
        this.courseHot.title = [];
        this.courseHot.value = [];
        // let num =60
        data.forEach((element) => {
          this.courseHot.title.push(element.courseName);
          this.courseHot.value.push(element.courseHeat);
        });
        // console.log(this.courseHot)
      } else {
        this.$message.error(msg);
      }
    },
  },
  created() {
    this.getCourseLabel();
    this.getSiteHot();
    this.getRealTimeAttendanceCount();
    this.getCourseHot();
    this.getTopBaseCount();
  },
};
</script>
<style lang="less" scoped>
.root {
  .section {
    width: 100%;
    .base-all {
      display: grid;
      grid-template-columns: repeat(3, 33.33%);
      .base-count {
        /deep/.count {
          color: #00c7ff;
        }
      }
    }
    .echart {
      margin: 2%;
      padding: 4%;
      background-color: #fff;
      border-radius: 5px;
      .site-search {
        margin-bottom: 4%;
        overflow: hidden;
        .site-select {
          float: right;
        }
      }
      .data-search {
        margin-bottom: 4%;
        overflow: hidden;
        .data-select {
          float: right;
        }
      }
    }
  }
}
</style>
