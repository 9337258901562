<template>
	<div id="main"></div>
</template>

<script>
import * as echarts from 'echarts/core'
import { TooltipComponent, GridComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer])
export default {
	props: ['siteHot'],
	mounted() {
		var chartDom = document.getElementById('main')
		var myChart = echarts.init(chartDom)
		var option

		option = {
			grid: {
				//柱状图位置
				top: 0,
				left: 25,
				right: 30,
				bottom: 5,
				containLabel: true,
			},
			xAxis: {
				type: 'value',

				axisLine: {
					show: true,
					lineStyle: {
						color: '#00c7ff',
						width: 1,
						type: 'dotted',
					},
				},
				axisTick: {
					show: false,
				},
				splitLine: {
					//格线样式
					show: false,
				},
			},
			yAxis: {
				type: 'category',
				data: this.siteHot.title,

				axisLine: {
					lineStyle: {
						color: '#00c7ff',
						width: 1,
						type: 'dotted',
					},
				},
				axisTick: {
					show: false,
				},
				axisLabel: {
					show: true,
					textStyle: {
						color: '#00c7ff',
					},
					formatter: function(value) {
						var res = value
						if (res.length > 5) {
							res = res.substring(0, 6) + '..'
						}
						return res
					},
				},
			},
			tooltip: {
				trigger: 'item',
                confine:true
			},
			series: [
				{
					data: this.siteHot.value,
					type: 'bar',
					label: {
						show: true,
						position: 'right',
						valueAnimation: true,
						color: '#00c7ff',
					},
					itemStyle: {
						//柱样式
						normal: {
							// barBorderRadius: [5, 5, 5, 5],
							color: '#00c7ff',
							opacity: 0.8,
						},
					},
				},
			],
		}

		option && myChart.setOption(option)
	},
}
</script>

<style lang="less" scoped>
#main {
	height: 100%;
	width: 100%;
}
</style>